import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LogoColourImage from "../../../static/content/zolt-logo-colour.png";
import {Link} from "gatsby";

export default function footer() {
  return (
    <div className={`vw-footer`}>
      <Container fluid>
        <Row>
          <Container>
            <Row className="justify-content-between">
              <Col xs={12} lg="auto">
                <a href="/">
                  <div className="vw-footer-logo">
                    <img src={LogoColourImage} />
                  </div>
                </a>
              </Col>
              <Col xs={12} sm={6} lg="auto">
                <div className="footer-title">{"Company"}</div>
                <a
                  target="_blank"
                  href="https://www.zolt.com.au/?section=about"
                >
                  {"About"}
                </a>
                <br />
                <a target="_blank" href="https://www.zolt.com.au/?section=why">
                  {"Why Zolt?"}
                </a>
              </Col>
              <Col xs={12} sm={6} lg="auto">
                <div className="footer-title">{"Plans"}</div>
                <Link to="/join-us?plan=humble">{"Humble Plan"}</Link>
                <br />
                <Link to="/join-us?plan=everyday">{"Everyday Plan"}</Link>
                <br />
                <Link to="/join-us?plan=express">{"Express Plan"}</Link>
                <br />
                <Link to="/join-us?plan=turbo">{"Turbo Plan"}</Link>
                <br />
              </Col>
              <Col xs={12} sm={6} lg="auto">
                <div className="footer-title">{"Support"}</div>
                <a href="https://www.zolt.com.au/?section=contact">{"Contact Us"}</a>
                <br />
                <a href="https://www.zolt.com.au/?section=faqs">{"FAQs"}</a>
                <br />
                <a target="_blank" href="https://www.zolt.com.au/general-terms-conditions">
                  {"General Terms & Conditions"}
                </a><br/>
                <a href="/esafety">
                  {"eSafety"}
                </a><br/>
                <a target="_blank" href="https://cdn1.vostro.cloud/provider/zolt/content/Zolt-Critical-Information-Summary.pdf">
                  {"Critical Information Summary"}
                </a><br/>
                <a target="_blank" href="https://cdn1.vostro.cloud/provider/zolt/content/complaints-handling-policy-zolt.pdf">
                  {"Complaint Handling"}
                </a><br/>
                <a  target="_blank" href="https://cdn1.vostro.cloud/provider/zolt/content/payment-assistance-summary-zolt.pdf">
                  {"Payment Assistance "}
                </a><br/>

              </Col>
            </Row>
            <Row className="copywrite">
              <Col style={{ textAlign: "center" }}>
                {"Copyright © 2021 Zolt - All Rights Reserved"}
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
    </div>
  );
}
